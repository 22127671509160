import React from 'react'

import { Paragraph, Stack, Time } from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Time"
    components={[{ component: Time }]}
    status={[{ type: 'accessible', version: '13.10.0' }]}
  >
    <Section>
      <Playground>
        <Stack gap="md">
          <Time date={new Date()} />
          <Time
            date={new Date()}
            withTime
          />
          <Time
            date={new Date()}
            withTime
            withYear
          />
        </Stack>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Komponentti tulostaa HTML:n semanttisen <Code>time</Code>-elementin.
        Ruudunlukijan asetuksista riippuu, luetaanko pelkkä päivämäärä oikein.
        Testatessa eri ruudunlukijoilla on havaittu, että esim. VoiceOverilla
        päivämäärä ja kellonaika -muotoinen <Code>Time</Code> luetaan oikein
        päivämääränä ja kellonaikana, mutta pelkkä päivämäärä luetaan väärin.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
